// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --configuration production` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

import { config } from './config';

// by default development configuration
config.captchaKey = '6LePn5geAAAAAGAGTEeRWhgZCxBofZQoGS2W_hrR';
config.apiUrl = 'https://devlabmgtbe.avivefuture.com/';

export const environment = config;
