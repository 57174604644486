import {environment} from "../../../environments/environment";

const API_URL = environment.apiUrl + 'api/';

export const URLS = {
    forget_password : API_URL + 'forget_password/',
    signup_user : API_URL + 'signup_user/',
    activate_account : API_URL + 'activate_account/',
    label : API_URL + 'label/',
    label_exists : API_URL + 'label/exists/',
    login : API_URL + 'login/',
    logout : API_URL + 'logout/',
    login_check : API_URL + 'logged_in_check/',
    incident : API_URL + 'incident/',
    user : API_URL + 'user/',
    organization : API_URL + 'organization/',
    location : API_URL + 'location/',
    mapping_data: API_URL + 'mapping_data/',
    aed: API_URL + 'aed/',
    forgot_reset_password: API_URL + 'forgot_reset_password/'
}
