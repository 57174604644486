import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "app/shared/services/users.service";
import { SnackBarService } from "app/shared/services/snack-bar.service";
import { ERROR_MESSAGES } from "app/shared/constants/messages";
import { Router } from "@angular/router";
import { LoginService } from "app/shared/services/login.service";
@Component({
  selector: "app-user-delete-popup",
  templateUrl: "./user-delete-popup.component.html",
  styleUrls: ["./user-delete-popup.component.scss"],
})
export class UserDeletePopupComponent {
  public layoutConf: any;
  userData;
  constructor(
    public dialogRef: MatDialogRef<UserDeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private loginService: LoginService,
    private snackBarService: SnackBarService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.userData = this.data;
  }

  closeDialogue() {
    this.dialogRef.close();
  }

  deleteUser() {
    this.userService
      .deleteUser(this.userData.id, this.data.orgId)
      .then((response: any) => {
        if (response.status === 200) {
          this.snackBarService.openSnackBar({
            message: response.data.message,
            time: 2,
          });
          this.loginService.signout()
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          this.snackBarService.openSnackBar({
            message: ERROR_MESSAGES.user_delete,
            time: 2,
          });
        } else if (error.response && error.response.status === 500) {
          this.snackBarService.openSnackBar({
            message: ERROR_MESSAGES.server_error,
            time: 2,
          });
        } else if (error.response && error.response.status === 403) {
          this.snackBarService.openSnackBar({
            message: error.response.data.detail,
            time: 2,
          });
        }
      });
    this.closeDialogue();
  }
}
