import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { IncidentLayoutComponent } from './shared/components/layouts/incident-layout/incident-layout.component';
import { WelcomeLayoutComponent } from './shared/components/layouts/welcome-layout/welcome-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { ConfirmPasswordComponent } from './views/sessions/confirm-password/confirm-password.component';
import { ActivateAccountComponent } from './views/sessions/activate-account/activate-account.component';
import { AccountWelcomeComponent } from './views/account/account-welcome/account-welcome.component';
import { AccountCreateComponent } from './views/account/account-create/account-create.component';
import { OrgGuard } from './shared/guards/org.guard';
import { AccountSelectComponent } from './views/account/account-select/account-select.component';
import { IncidentSearchComponent } from './views/incidents/incident-search/incident-search.component';
import { UserProfileComponent } from './views/users/user-profile/user-profile.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session'}
      },
      {
        path: "forgot_reset_password/:token-id/:token",
        component: ConfirmPasswordComponent,
        data: { title: "confirm-password" }
      },
      {
        path: "activate/:token-id/:token",
        component: ActivateAccountComponent,
        data: { title: "Activate" }
      }
    ]
  },
  {
    path: '',
    component: IncidentLayoutComponent,
    children: [
      {
        path: 'incidents',
        loadChildren: () => import('./views/incidents/incidents.module').then(m => m.IncidentssModule),
        data: { title: 'Incident Details'}
      }
    ]
  },
  {
    path: '',
    component: WelcomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "welcome",
        component: AccountWelcomeComponent,
        canActivate: [AuthGuard],
        data: { title: "Account Welcome" }
      },
      {
        path: "account/create",
        component: AccountCreateComponent ,
        canActivate: [AuthGuard],
        data: { title: "Account create" }
      },
      {
        path: "account/select",
        component: AccountSelectComponent ,
        canActivate: [AuthGuard],
        data: { title: "Account Select" }
      },
      {
        path: "profile",
        component: UserProfileComponent,
        canActivate: [AuthGuard],
        data: { title: "User Profile" }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [OrgGuard,AuthGuard],
        data: { title: 'Dashboard'}
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
        canActivate: [OrgGuard,AuthGuard],
        data: { title: 'Users'}
      },
      {
        path: 'account',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard],
        data: { title: 'Account'}
      },
      {
        path: 'aed',
        loadChildren: () => import('./views/aed/aed.module').then(m => m.AedModule),
        canActivate: [OrgGuard,AuthGuard],
        data: { title: 'Aed'}
      },
      {
        path: 'cpr',
        loadChildren: () => import('./views/cpr/cpr.module').then(m => m.CprModule),
        canActivate: [OrgGuard,AuthGuard],
        data: { title: 'Cpr'}
      },
      {
        path: 'location',
        loadChildren: () => import('./views/location/location.module').then(m => m.LocationModule),
        canActivate: [OrgGuard,AuthGuard],
        data: { title: 'Location'}
      },
      {
        path: 'label',
        loadChildren: () => import('./views/label/label.module').then(m => m.LabelModule),
        canActivate: [AuthGuard],
        data: { title: 'Label'}
      },
      {
        path: 'incidents-auth',
        loadChildren: () => import('./views/incidents/incidents.module').then(m => m.IncidentssModule),
        canActivate: [AuthGuard],
        data: { title: 'Incident Details'}
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

