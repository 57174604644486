<mat-sidenav-container class="app-admin-container">
  <mat-sidenav-content>
    <div class="header topbar" [perfectScrollbar]="" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="logo"><img src="assets/images/svg-icons/logo_svg.svg" alt=""></div>
      <app-av-notification-profile fxLayout="row" fxLayoutAlign="end center"></app-av-notification-profile>
    </div>
    <div class="welcome-layout">
    <div class="container container-dynamic">
      <router-outlet></router-outlet>
    </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
