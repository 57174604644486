import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { changePwd } from "app/shared/models/user.model";
import { UserService } from "app/shared/services/users.service";
import { SnackBarService } from "app/shared/services/snack-bar.service";
import { PasswordStrengthValidator } from "app/shared/helpers/password-strength.validator";
import { ConfirmPasswordValidator } from 'app/shared/helpers/match-fields.validator';
import { MatProgressBar } from "@angular/material/progress-bar";
import { ERROR_MESSAGES } from "app/shared/constants/messages";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  public layoutConf: any;
  changePwdForm: FormGroup;
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private userService: UserService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.setPwdForm();
    }
  changePassword() {
    const values = this.changePwdForm.value;
    this.progressBar.mode = "indeterminate";
    let submittedValues = new changePwd(values);
    this.userService
      .changePassword(this.data.data, submittedValues)
      .then((response: any) => {
        this.progressBar.mode = "determinate";
        if (response.status === 200) {
          this.snackBarService.openSnackBar({
            message: response.data.message,
            time: 2,
          });
          this.closeDialogue();
        }
      })
      .catch((error) => {
        this.progressBar.mode = "determinate";
        if (error.response && error.response.status === 400) {
          this.snackBarService.openSnackBar({
            message: error.response.data.old_password,
            time: 2,
          });
          this.closeDialogue();
        } else if (error.response && error.response.status === 500) {
          this.snackBarService.openSnackBar({
            message: ERROR_MESSAGES.server_error,
            time: 2,
          });
          this.closeDialogue();
        } else if (error.response && error.response.status === 403) {
          this.snackBarService.openSnackBar({
            message: error.response.data.detail,
            time: 2,
          });
          this.closeDialogue();
        }

      });
  }
  setPwdForm() {
    this.changePwdForm = new FormGroup({
      oldPassword: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(128),
          Validators.minLength(6),
          PasswordStrengthValidator,
        ])
      ),
      newPassword: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(128),
          Validators.minLength(6),
          PasswordStrengthValidator,
        ])
      ),
      confirmPassword: new FormControl('', [ Validators.required ]),
    },{
      validators: ConfirmPasswordValidator('newPassword', 'confirmPassword')
    });

  }
//to close dialogue box
  closeDialogue() {
    this.dialogRef.close();
  }
}
