import { Component, OnInit, Input } from '@angular/core';
import appVersion from '../../../../../appVersion.json';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html',
})
export class SidenavComponent {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  public appVer: string;

  constructor() {}
  ngOnInit() {
    if (environment.production === false) this.appVer = appVersion.appVersion;
  }
}
