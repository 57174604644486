import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import {
  SUCCESS_MESSAGES,
  ERROR_MESSAGES,
  DESCRIPTIONS
} from 'app/shared/constants/messages';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatButton } from '@angular/material/button';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ProgressbarService } from 'app/shared/services/progressbar.service';
import { environment } from 'environments/environment';
import { ConfirmPasswordValidator } from 'app/shared/helpers/match-fields.validator';
import { PasswordStrengthValidator } from 'app/shared/helpers/password-strength.validator';
import { BlankInputValidator } from 'app/shared/helpers/blank-input.validator';
import { User } from 'app/shared/models/user.model';
import { ValidateInput } from 'app/shared/helpers/utils';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss'],
})
export class ActivateAccountComponent implements OnInit {
  activeroute = [];
  searchValue: object;
  submittedValues: object;
  routeValues: string;
  tokensVariables: string;
  token: string;
  uidbId: string;
  appVer: string;
  message: string;
  activated: boolean;

  /* */
  registerTask = false;
  btnSearchDisabled = true;
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  @ViewChild(MatButton) submitButton: MatButton;

  // for snack bar
  durationInSeconds = 1;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  registerForm: FormGroup;
  registerDisabled = true;
  mailErrorMessage: string;
  passErrorMessage: string;

  validateInput = ValidateInput;
  /*  */

  constructor(
    public auth: AuthService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    public _snackBar: MatSnackBar,
    private ps: ProgressbarService
  ) {
    this.submittedValues = {
      uidb64: this.activatedRoute.snapshot.paramMap.get('token-id'),
      token: this.activatedRoute.snapshot.paramMap.get('token'),
    };
  }
  siteKey: string=environment.captchaKey;
  ngOnInit() {
    this.registerTask = true;
    this.setRegistrationForm();
    this.activeRouter();
  }

  activeRouter(){
    this.auth
      .activeRouter(this.submittedValues)
      .then((response: any) => {
        if (response.status === 200) {
          this.message = SUCCESS_MESSAGES.account_activation;
          this.activated = true;
          this.registerTask = false;
          this.openSnakbar(this.message);
          this.router.navigateByUrl('sessions/signin');
        } else {
          this.message = ERROR_MESSAGES.account_activation;
          this.activated = false;
          this.registerTask = false;
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400 && error.response.data === DESCRIPTIONS.activate_message) {
            this.registerTask = true;
            this.message = DESCRIPTIONS.activate_message;
          } else if (error.response.status === 400) {
            this.message = ERROR_MESSAGES.account_activation;
            this.registerTask = false;
          }
        }
        this.activated = false;
    });
  }
  // set registration form
  setRegistrationForm() {
    this.registerForm = new FormGroup({
      first_name: new FormControl('',[Validators.required, Validators.maxLength(150), Validators.minLength(1),
        BlankInputValidator]),
      last_name: new FormControl('',[Validators.required, Validators.maxLength(150), Validators.minLength(1), BlankInputValidator]),
      phone: new FormControl('',[Validators.required, Validators.maxLength(13), Validators.minLength(10), BlankInputValidator, Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})')]),
      password: new FormControl('',Validators.compose([
        Validators.required,
        Validators.maxLength(128),
        Validators.minLength(6),
        PasswordStrengthValidator
      ])),
      confirmPassword: new FormControl('', Validators.compose([
        Validators.required
      ])),
      email_updates: new FormControl(false),
    },{
      validators: ConfirmPasswordValidator('password', 'confirmPassword')
    });

  }

  // submit register form
  register() {
    this.ps.changeStatus('indeterminate');
    if(this.registerForm.valid){
      const signupData = this.registerForm.value;
      const formValues = new User(signupData)
      let message: string;
      this.auth.registerUser(this.submittedValues, formValues)
      .then((response)=>{
        this.ps.changeStatus('determinate');
        if(response.status === 200){
          message = SUCCESS_MESSAGES.account_activation;
          this.router.navigateByUrl('sessions/signin');
        } else {
          message = ERROR_MESSAGES.user_add;
        }
        this.openSnakbar(message);
      }).catch((error) => {
        this.ps.changeStatus('determinate');
        if (error.response == null || error.response == undefined) {
          message = ERROR_MESSAGES.account_activation;
        } else if ((error.response.status === 400) || (error.response.status)) {
          message = ERROR_MESSAGES.account_activation;
        }
        this.openSnakbar(message);
      });
    }
  }
  resolved(captchaResponse) {
    if(captchaResponse){
      this.registerDisabled = false;
    }
  }

  openSnakbar(message){
    this._snackBar.open(message, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000
    });
  }
}
