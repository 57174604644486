import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'app/shared/services/account.service';

@Component({
  selector: 'app-av-account-name',
  templateUrl: './av-account-name.component.html',
  styleUrls: ['./av-account-name.component.scss'],
})
export class AvAccountNameComponent implements OnInit {
  accountName: string = '';

  constructor(
    private router: Router,
    private accountService: AccountService
  ) {
    this.accountService.accountName.subscribe((account: any) =>{
      this.accountName = account ? account.name : '';
    })
  }

  ngOnInit(): void {}

  goToAccountSelect() {
    this.router.navigateByUrl('account/select');
  }

  ellipsify(str) {
    if (str.length > 10) {
      return str.substring(0, 15) + '...';
    } else {
      return str;
    }
  }
}
