import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-av-pg-title-heading',
  templateUrl: './av-pg-title-heading.component.html',
  styleUrls: ['./av-pg-title-heading.component.scss']
})
export class AvPgTitleHeadingComponent implements OnInit {
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
