import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AccountService } from 'app/shared/services/account.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from 'app/shared/constants/messages';
import { Router } from '@angular/router';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { LOCAL_STORAGE } from 'app/shared/constants/local-storage';
import { Pagination } from 'app/shared/models/pagination';

@Component({
  selector: 'app-account-select',
  templateUrl: './account-select.component.html',
  styleUrls: ['./account-select.component.scss']
})
export class AccountSelectComponent implements OnInit {

 // for snack bar
 durationInSeconds = 1;
 horizontalPosition: MatSnackBarHorizontalPosition = 'right';
 verticalPosition: MatSnackBarVerticalPosition = 'top';

  // Table
  paginationData = Pagination.pageData();
  pageSizeOptions = this.paginationData.pageSizeOptions;
  pageSize = this.paginationData.pageSize;
  pageLength = this.paginationData.pageLength;
  pageIndex = this.paginationData.pageIndex;
  previousPageIndex: number;
  displayedColumns: string[] = ['name', 'id', 'no_of_aeds', 'no_of_users', 'health'];
  dataSource: any;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  searchQuery: string;

  constructor(
    private accountService: AccountService,
    private router: Router,
    public _snackBar: MatSnackBar,
    private ls: LocalStoreService
  ) { }

  ngOnInit(): void {
    this.getOrganizations();
  }


  getOrganizations() {
    this.accountService.getOrganizations(this.searchQuery, this.pageIndex)
    .then((response: any)=>{
      if(response.status === 200){
        this.dataSource = response.data.data;
        this.pageLength = response.data.pagination.total_matching;
        this.pageIndex = response.data.pagination.page_no;
      }
    })
    .catch((error) => {
      if(error.response && error.response.status === 400) {
        this.openSnakbar(ERROR_MESSAGES.get_organizations);
      }
    })
  }

  applyFilter(event) {
    this.searchQuery = event;
    this.pageIndex = 1;
    this.getOrganizations();
  }

  goToCreateAccount(){
    this.router.navigateByUrl('account/create');
  }

  setAccount(row){
    const accountDetails = { id: row.id, name: row.name }
    this.ls.setItem(LOCAL_STORAGE.ACCOUNT,accountDetails)
    this.accountService.changeAccount(accountDetails);
    this.router.navigateByUrl('dashboard');
  }

  openSnakbar(message){
    this._snackBar.open(message, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000
    });
  }

  pageChanged(event){
    this.pageSize = event.pageSize;
    this.pageLength = event.length;
    this.pageIndex = event.pageIndex + 1;
    this.previousPageIndex = event.previousPageIndex + 1;
    this.getOrganizations();
  }
}
