<app-av-pg>
  <app-av-pg-title>
    <app-av-pg-title-heading title="Create Organization"></app-av-pg-title-heading>
    <app-av-pg-title-buttons>
      <button mat-stroked-button id="back-button" (click)="goBack()">CANCEL</button>
      <button mat-flat-button color="primary"  id="save-button" (click)="createAccount()" [disabled]="organizationForm.invalid">SAVE</button>
    </app-av-pg-title-buttons>
  </app-av-pg-title>
  <form [formGroup]="organizationForm" class="form-controllers">
    <mat-progress-bar mode="determinate" class="session-progress"></mat-progress-bar>
    <div fxLayout="column" fxLayout.lt-sm="column">
      <p fxFlex="100" class="sec-title">
        <span>Organization Info </span>
      </p>
      <div fxLayout="row" fxFlexAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column">
        <mat-form-field fxFlex="49.3">
          <mat-label>Organization Name</mat-label>
          <input matInput formControlName="name" id="name" placeholder="" required value="">
          <mat-error id="name-error" *ngIf="organizationFormCtrls.name.hasError('required')">
            Organization Name is Required
          </mat-error>
          <mat-error
            *ngIf="organizationFormCtrls.name.hasError('blankSpaceOnly')">
            {{organizationFormCtrls.name.errors['blankSpaceOnly']}} </mat-error>
          <mat-error id="name-err" *ngIf="organizationFormCtrls.name.hasError('nameErr')"> {{nameErr}} </mat-error>
        </mat-form-field>
        <span></span>
      </div>
      <div fxLayout="row" fxFlexAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column">
        <mat-form-field>
          <mat-label>Organization's Phone</mat-label>
          <input matInput formControlName="phoneNumber" id="phoneNumber" placeholder="" value="">
          <mat-error *ngIf="organizationFormCtrls.phoneNumber.hasError('required')">
            Organization's Phone is Required
          </mat-error>
          <mat-error class="valid-phone-number"
            *ngIf="organizationFormCtrls.phoneNumber.hasError('blankSpaceOnly') || organizationFormCtrls.phoneNumber.hasError('pattern')">
            Please enter a valid phone number  </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Organization's Email</mat-label>
          <input matInput formControlName="emailId" id="emailId" placeholder="" required value="">
          <mat-error id="email-error" *ngIf="organizationFormCtrls.emailId.hasError('required')">
            Organization's Email is Required
          </mat-error>
          <mat-error id="valid-error" *ngIf="organizationFormCtrls.emailId.hasError('email')">
            Please enter a valid email
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="column" fxFlexAlign="start center" fxLayout.lt-sm="column">
      <p fxFlex="100" class="sec-title">
        <span class="d-flex">Location Info <mat-icon class="error-icon"> error </mat-icon> </span>
      </p>
      <div fxLayout="row" fxFlexAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column">
        <mat-form-field fxFlex="49.3">
          <mat-label>Location Name</mat-label>
          <input matInput formControlName="locationName" id="locationName" placeholder="" value="">
          <mat-error
            *ngIf="organizationFormCtrls.locationName.hasError('blankSpaceOnly')">
            {{organizationFormCtrls.locationName.errors['blankSpaceOnly']}} </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxFlexAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column">
        <mat-form-field>
          <mat-label>Street Address 1</mat-label>
          <input matInput formControlName="streetAddress" id="streetAddress" placeholder="" value="">
          <mat-error
            *ngIf="organizationFormCtrls.streetAddress.hasError('blankSpaceOnly')">
            {{organizationFormCtrls.streetAddress.errors['blankSpaceOnly']}} </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Street Address 2</mat-label>
          <input matInput formControlName="streetAddress2" id="streetAddress2" placeholder="" value="">
          <mat-error
            *ngIf="organizationFormCtrls.streetAddress2.hasError('blankSpaceOnly')">
            {{organizationFormCtrls.streetAddress2.errors['blankSpaceOnly']}} </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxFlexAlign="start center" fxLayoutGap="16px" fxLayout.lt-md="column">
        <mat-form-field>
          <mat-label>City</mat-label>
          <input matInput formControlName="city" id="city" placeholder="" value="">
          <mat-error
            *ngIf="organizationFormCtrls.city.hasError('blankSpaceOnly')">
            {{organizationFormCtrls.city.errors['blankSpaceOnly']}} </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>State</mat-label>
          <mat-select formControlName="state" id="state" ngDefaultControl>
            <mat-option></mat-option>
          </mat-select>
          <mat-error ngxErrors="organizationFormCtrls.state.errors">
            State is Required
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Zip Code</mat-label>
          <input matInput formControlName="zipCode" id="zipCode" placeholder="" value="">
          <mat-error
            *ngIf="organizationFormCtrls.zipCode.hasError('blankSpaceOnly')">
            {{organizationFormCtrls.zipCode.errors['blankSpaceOnly']}} </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Country</mat-label>
          <mat-select formControlName="country" id="country" ngDefaultControl>
            <mat-option value="1">India</mat-option>
          </mat-select>
          <mat-error ngxErrors="organizationFormCtrls.country.errors">
            Country is Required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</app-av-pg>
