import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;  // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {

  iconMenu: IMenuItem[] = [
    {
      name: 'Dashboard',
      type: 'link',
      svgIcon: 'dashboard',
      state: 'dashboard',
    },
    {
      name: 'Fleet Map',
      type: 'link',
      svgIcon: 'fleet-map',
      state: 'fleet map',
    },
    {
      name: 'Locations',
      type: 'link',
      svgIcon: 'locations',
      state: 'location/list',
    },
    {
      name: 'AEDs',
      type: 'link',
      svgIcon: 'aeds',
      state: 'aed/list',
    },
    {
      name: 'Users',
      type: 'link',
      svgIcon: 'users',
      state: 'users/list',
    },
    {
      name: 'Incidents',
      type: 'link',
      svgIcon: 'incidents',
      state: 'incidents-auth/list',
    },
    {
      name: 'Labels',
      type: 'link',
      svgIcon: 'labels',
      state: 'label',
    },
    {
      type: 'separator',
    },
    {
      name: 'Store',
      type: 'link',
      svgIcon: 'store',
      state: 'store',
    },
    {
      name: 'Organization Settings',
      type: 'link',
      svgIcon: 'accountsettings',
      state: 'account',
    }
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  constructor() {}

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    this.menuItems.next(this.iconMenu);
  }
}
