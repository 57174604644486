import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-av-delete-popup',
  templateUrl: './av-delete-popup.component.html',
  styleUrls: ['./av-delete-popup.component.scss']
})
export class AvDeletePopupComponent {

  layoutConf: any;
  @Output() closeDlg: EventEmitter<any> = new EventEmitter();
  @Output() saveData: EventEmitter<any> = new EventEmitter();
  @Input() heading: string;
  @Input() isDisable: boolean = false;
  @Input() action: string;
  @Input() closeText: string;
  constructor( ) {
    this.action = 'SAVE'
    this.closeText = 'EXIT'
  }

  closeDialogue() {
    this.closeDlg.emit();
  }

  save() {
    this.saveData.emit();
  }
}
