export const USERS = {
  status: 200,
  data: {
    data: [
      {
        first_name: 'vinoy',
        id: 30,
        labels: [{ id: 1, name: 'test' }],
        last_name: 'citrus',
        role: 'admin',
      },
      {
        first_name: 'joge',
        id: 39,
        labels: [{ id: 2, name: 'test1' }],
        last_name: 'lastname',
        role: 'manager',
      },
      {
        first_name: 'Joe',
        id: 5,
        labels: [{ id: 3, name: 'test3' }],
        last_name: 'Cole',
        role: 'admin',
      },
    ],
    summary: {
      admin: 2,
      manager: 1,
      responder: 0,
      users: 3,
    },
  },

  pagination: {
    page_no: 1,
    total_matching: 12,
  },
};
export const USERS_DETAILS = {
  status: 200,
  data: {
    first_name: 'Abdul',
    last_name: 'Price',
    email: 'abdul@avive.life',
    id: 66,
    organizations: [],
    aeds: [],
    locations: [],
    role: 'admin',
    is_active: true,
    phone: '1212121212',
    status: 'Active',
    labels: [{ id: 1, name: 'test' }],
  },
};
export const roleGroup = [
  { id: 'admin', value: 1, text: 'Admin', checked: false },
  { id: 'manager', value: 2, text: 'Manager', checked: true },
  { id: 'responder', value: 3, text: 'Responder', checked: false },
];
export const notification = { push: 'Push', email: 'Email', text: 'Text' };

export const USERS_PROFILE = {
  status: 200,
  data: {
    first_name: 'Abdul',
    last_name: 'Price',
    email: 'abdul@avive.life',
    id: 66,
    phone: '1212121212',
    profile_image: 'test-file.jpg',
    notification_settings: {
      1: { 1: false, 2: false, 3: false },
      2: { 1: false, 2: false, 3: false },
    },
  },
};
export const health = {
  healthy_color: 'green',
  unhealthy_color: 'red',
  unknown_color: 'grey',
  risk_color: 'yellow',
  healthy_text: '',
  unhealthy_text: 'Pads Expired',
  risk_text: 'Pads Expiring Soon',
  unknown_text: 'Initialization Needed',
};

export const USER_PERMISSIONS = {
  manageUser : 'Add & Manage Users' ,orderSupplies : 'Order AEDs & Supplies', createLabels : 'Create Labels'
}
export const ALL_USER_SELECT = {
  first_name: 'All AED Managers',
  last_name: '',
  id: 0,
  email: '',
  phone: '',
  profile_image: '',
  organizations: [],
  locations: [],
  is_active: true,
  status: ' ',
  last_login: '',
  role: ' ',
  labels: [],
};

export const NO_USER_SELECT = {
  first_name: 'No Records Found',
  last_name: '',
  id: 0,
  email: '',
  phone: '',
  profile_image: '',
  organizations: [],
  locations: [],
  is_active: true,
  status: ' ',
  last_login: '',
  role: ' ',
  labels: [],
};
