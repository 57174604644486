<span *ngIf="registerTask">
  <p class="card-title"> Register </p>
  <form [formGroup]="registerForm" (ngSubmit)="register()">
    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input matInput type="" name="first_name" id="first_name" formControlName="first_name"
        placeholder="First Name" value="">
      <mat-error *ngIf="registerForm.controls['first_name'].hasError('required') && registerForm.controls['first_name'].touched"> First Name is required </mat-error>
      <mat-error class="password-pattern"
        *ngIf="registerForm.controls['first_name'].hasError('blankSpaceOnly')">
        {{registerForm.controls['first_name'].errors['blankSpaceOnly']}} </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Last Name</mat-label>
      <input matInput type="" name="last_name" id="last_name" formControlName="last_name"
        ngDefaultControl placeholder="Last Name" value="">
      <mat-error *ngIf="registerForm.controls['last_name'].hasError('required') && registerForm.controls['last_name'].touched"> Last Name is required </mat-error>
      <mat-error class="password-pattern"
        *ngIf="registerForm.controls['last_name'].hasError('blankSpaceOnly')">
        {{registerForm.controls['last_name'].errors['blankSpaceOnly']}} </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Phone Number</mat-label>
      <input matInput type="" name="phone" id="phone" formControlName="phone"
        ngDefaultControl placeholder="Phone Number" value="" (keypress)="validateInput($event)">
      <mat-error
        *ngIf="registerForm.controls['phone'].hasError('required') && registerForm.controls['phone'].touched"> Phone Number is required </mat-error>
      <mat-error class="valid-phone-number"
        *ngIf="registerForm.controls['phone'].hasError('pattern')">
        Please enter a valid phone number  </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Create Password</mat-label>
      <input matInput type="password" name="password" id="password" formControlName="password"
        ngDefaultControl placeholder="Password" value="">
      <mat-error *ngIf="registerForm.controls['password'].hasError('required') && registerForm.controls['password'].touched"> Password is required </mat-error>
      <mat-error class="password-pattern"
        *ngIf="registerForm.controls['password'].hasError('passwordStrength')">
        {{registerForm.controls['password'].errors['passwordStrength']}} </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Re-enter Password</mat-label>
      <input matInput type="password" name="confirmPassword" id="confirmPassword"
        formControlName="confirmPassword" ngDefaultControl placeholder="Password" value="">
      <mat-error
        *ngIf="registerForm.controls['confirmPassword'].hasError('required') && registerForm.controls['confirmPassword'].touched"> Please re enter password </mat-error>
      <mat-error
      *ngIf="registerForm.controls['confirmPassword'].hasError('confirmPasswordValidator') && registerForm.controls['confirmPassword'].touched"
      > Password does not match </mat-error>
    </mat-form-field>
    <div class="checkbox pb-16 d-flex justify-content-start">
      <mat-checkbox color="primary" name="email_updates" id="email_updates" formControlName="email_updates"
        ngDefaultControl fxLayout.lt-md="column" class="box-font">Send me important updates and announcements.
      </mat-checkbox>
    </div>

    <div fxLayout="column" fxLayoutAlign="end end" fxLayoutAlign.lt-sm="start start" class="card-buttons">
      <re-captcha
        id="recaptcha"
        (resolved)="resolved($event)"
        class="captch-style"
        [(siteKey)]="siteKey"
        ></re-captcha>
      <button id="register-button"  mat-flat-button class="mat-primary" [disabled]="!registerForm.valid">REGISTER</button>
    </div>
  </form>

</span>
<span *ngIf="!registerTask">
  <mat-card>
    <mat-card-content>
      <div fxLayout="row" fxFlex="100" class="err-message" *ngIf="message" id="err-message" fxLayoutAlign="center center">
        {{message}}
      </div>
    </mat-card-content>
  </mat-card>
</span>
