import { ILabel, Label } from './label.model';

export class Location {
  id: number;
  name: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  country?: string;
  privacy?: number;
  allow_911?: boolean;
  summary? = {
    aeds: 0,
    battery: {
      good: 0,
      low: 0,
      unknown: 0,
    },
    pad_cartridges: {
      good: 0,
      replace: 0,
      upcoming: 0,
      're-install': 0,
      unknown: 0,
    },
    other_issues: {
      critical_test: 0,
      training: 0,
      software: 0,
      temp: 0,
      other: 0,
    },
    sync_status: {
      synced: 0,
      unsynced: 0,
    },
  };
  labels: Label[] = [];
  schedule: Schedule;

  constructor(location) {
    if (typeof location == 'object') {
      this.id = location.id;
      this.name = location.name;
      this.address_line_1 = location.address_line_1;
      this.address_line_2 = location.address_line_2;
      this.city = location.city;
      this.state = location.state;
      this.zip_code = location.zip_code;
      this.country = location.country;
      this.privacy = location.privacy;
      this.allow_911 = location.allow_911;

      if (location.labels !== undefined && location.labels !== null) {
        for (let each of location.labels) {
          this.labels.push(new Label(each as ILabel));
        }
      }

      // TODO: uncomment it once the schedule format is fixed in API
      // if (location.schedule !== undefined && location.schedule !== null) {
      //     this.schedule = new Schedule(location.schedule)
      // }

      if (typeof location.summary == 'object') {
        this.summary = {
          ...this.summary,
          ...location.summary,
        };
      }
    } else {
      this.name = '';
      this.address_line_1 = '';
      this.address_line_2 = '';
      this.city = '';
      this.state = '';
      this.zip_code = '';
      this.country = '';
      this.privacy = 0;
      this.allow_911 = false;
      this.labels = [];
    }
  }
}

export class DaySchedule {
  dayEncoding = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  };
  start: number;
  end: number;
  day: number;
  dayText: string;
  availability = true;
  constructor(day: number, start: number, end: number) {
    if (day !== undefined && start !== undefined && end !== undefined) {
      this.day = day;
      this.dayText = this.dayEncoding[day];
      this.start = start;
      this.end = end;
      if (this.start === 0 && this.end === 0) {
        this.availability = false;
      }
    }
  }

  getEncodeValue() {
    let dayValue = this.day;
    return {
      dayValue: [this.start, this.end],
    };
  }
}

export class Schedule {
  monday: DaySchedule;
  tuesday: DaySchedule;
  wednesday: DaySchedule;
  thursday: DaySchedule;
  friday: DaySchedule;
  saturday: DaySchedule;
  sunday: DaySchedule;

  constructor(schedule) {
    if (schedule !== undefined) {
      this.monday = new DaySchedule(1, schedule['1'][0], schedule['1'][1]);
      this.tuesday = new DaySchedule(2, schedule['2'][0], schedule['2'][1]);
      this.wednesday = new DaySchedule(3, schedule['3'][0], schedule['3'][1]);
      this.thursday = new DaySchedule(4, schedule['4'][0], schedule['4'][1]);
      this.friday = new DaySchedule(5, schedule['5'][0], schedule['5'][1]);
      this.saturday = new DaySchedule(6, schedule['6'][0], schedule['6'][1]);
      this.sunday = new DaySchedule(7, schedule['7'][0], schedule['7'][1]);
    }
  }

  getEncodeValue() {
    return {
      ...this.monday.getEncodeValue(),
      ...this.tuesday.getEncodeValue(),
      ...this.wednesday.getEncodeValue(),
      ...this.thursday.getEncodeValue(),
      ...this.friday.getEncodeValue(),
      ...this.saturday.getEncodeValue(),
      ...this.sunday.getEncodeValue(),
    };
  }
}

export class addLocation {
  name?: string;
  color?: string;
  id?: string;

  constructor(eu) {
    this.name = eu.name;
    this.color = eu.color;
    this.id = eu.id;
  }

  //  TODO: update values with APIs

  static users(): addLocation[] {
    return [{ name: 'John Que', color: '' }];
  }

  //  TODO: update values with APIs

  static labels(): addLocation[] {
    return [
      { name: 'Headquarters', color: '' },
      { name: 'Corporate', color: '' },
    ];
  }
}

export class locationTable {
  id: number;
  name: string;
  labels: string;
  locationManager: string;
  health: string;
  color: string;

  constructor(ut) {
    this.name = ut.name;
    this.labels = ut.labels;

    this.locationManager = ut.locationManager;
    this.health = ut.health;
    this.color = ut.color;
  }
}

export class locationHealth {
  health: any;
  count: number;

  constructor(ut) {
    this.health = ut.health;
    this.count = ut.count;
  }

  static healthData(): locationHealth {
    return {
      count: 4,
      health: {
        healthy: 1,
        unhealthy: 0,
        at_risk: 0,
        unknown: 0,
      },
    };
  }
}

export class editLocation {
  name?: string;
  color?: string;

  constructor(eu) {
    this.name = eu.name;
  }

  //  TODO: update values with APIs

  static users(): editLocation[] {
    return [{ name: 'John Que' }];
  }

  //  TODO: update values with APIs

  static labels(): editLocation[] {
    return [{ name: 'Headquarters' }, { name: 'Corporate' }];
  }
}

export class aedAssignmentsTable {
  aed_name: string;
  health: string;
  health_text: string;

  static aedHealth(): aedAssignmentsTable[] {
    return [
      { aed_name: 'C34309', health: 'active', health_text: '' },
      {
        aed_name: 'C34310',
        health: 'inactive',
        health_text: 'Battery Low, Training Pads Inserted',
      },
      { aed_name: 'C34311', health: 'inactive', health_text: 'Battery Low' },
      {
        aed_name: 'C34312',
        health: 'warning',
        health_text: 'Pads Expiring soon',
      },
    ];
  }
}
