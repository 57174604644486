import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BlankInputValidator } from "app/shared/helpers/blank-input.validator";
import { Location } from "@angular/common";
import { UserService } from "app/shared/services/users.service";
import { LOCAL_STORAGE } from "app/shared/constants/local-storage";
import { LocalStoreService } from "app/shared/services/local-store.service";
import { IUser, User, MapData } from "app/shared/models/user.model";
import { DialogueboxService } from 'app/shared/services/dialoguebox.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { ERROR_MESSAGES, DESCRIPTIONS,SUCCESS_MESSAGES } from "app/shared/constants/messages";
import { MatProgressBar } from "@angular/material/progress-bar";
import { validateBasis } from "@angular/flex-layout";
import { notification } from "app/shared/constants/users";
import { LoginService } from "app/shared/services/login.service";
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { UserDeletePopupComponent } from '../user-delete-popup/user-delete-popup.component';


@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  progressbarstatus: any;
  color = "primary";
  pushNotificationDevice = false;
  emailNotificationDevice = false;
  textNotificationDevice = false;
  pushNotificationResponder = false;
  emailNotificationResponder = false;
  textNotificationResponder = false;
  disabled = false;
  labelPosition = "before";
  horizontalPosition: MatSnackBarHorizontalPosition = "right";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  userProfileForm: FormGroup;
  usersInfo = new User("");
  userDetails: any = { id: "", profile_image: "" };
  userNotifications: any = {};
  mappingData: any;
  notificationSettingsDevice: any;
  notificationSettingsResponder: any;
  userData: any = [{}];
  typeDevice: string;
  typeResponder: string;
  notificationData = notification
  uploadProfile: string;
  fileExtension: string;
  orgDetails: any = { id: '' };
  dlgOpened: boolean;
  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private loc: Location,
    private userService: UserService,
    private ls: LocalStoreService,
    public _snackBar: MatSnackBar,
    public ds: DialogueboxService

  ) {}

  ngOnInit(): void {
    const orgDetails = this.ls.getItem(LOCAL_STORAGE.ACCOUNT);
    this.orgDetails.id = orgDetails ? orgDetails.id : '';
    const userDetails = this.ls.getItem(LOCAL_STORAGE.APP_USER);
    this.userDetails.id = userDetails ? userDetails.id : "";
    this.userDetails.profile_image = userDetails
      ? userDetails.profile_image
      : "";
    this.mappingData = this.ls.getItem(LOCAL_STORAGE.MAPPING_DATA);
    this.userService.profileImageUpdate.next(this.userDetails.profile_image);
    this.setProfileForm();
    this.getUserProfile();
    }

  get userProfileFormCtrls() {
   return this.userProfileForm.controls;
  }

  uploadImage(event) {

    this.uploadProfile = event.target.files[0];
    if (this.uploadProfile) {
      this.progressBar.mode = "indeterminate";
      const fileName = event.target.files[0].name;
      this.fileExtension = fileName.split('.').pop();
      if(this.fileExtension == 'jpeg' || this.fileExtension == 'jpg' || this.fileExtension == 'png' ){
        if(event.target.files[0].size <=1048576){
          const formData = new FormData();
          formData.append("profile_image", this.uploadProfile);
          this.userService
            .uploadProfileImage(this.userDetails.id, formData)
            .then((response: any) => {
              this.progressBar.mode = "determinate";
              if (response.status === 200) {
                this.userDetails.profile_image = response.data.url;
                this.userService.profileImageUpdate.next(this.userDetails.profile_image);
                this.openSnakbar(response.data.message);
                this.getUserProfile()
                this.userProfileForm.markAsPristine()
              }
            })
            .catch((error) => {
              this.progressBar.mode = "determinate";
              if (error.response) {
                if (error.response.status === 400) {
                  this.openSnakbar(error.response.data.profile_image);
                }
              } else {
                this.openSnakbar(ERROR_MESSAGES.image_upload_error);
              }
            });
        }
        else {
          this.openSnakbar(ERROR_MESSAGES.user_profile_photo);
          this.progressBar.mode = "determinate";
        }

      }
      else {
        this.openSnakbar(ERROR_MESSAGES.image_type_error);
        this.progressBar.mode = "determinate";
      }

    }
  }
  setProfileForm() {
    this.userProfileForm = new FormGroup({
      profilePicture: new FormControl(""),
      firstname: new FormControl("", [
        Validators.required,
        Validators.maxLength(150),
        Validators.minLength(1),
        BlankInputValidator,
      ]),
      lastname: new FormControl("", [
        Validators.required,
        Validators.maxLength(150),
        Validators.minLength(1),
        BlankInputValidator,
      ]),
      email: new FormControl("", [Validators.email, Validators.minLength(5)]),
      phonenumber: new FormControl("", [
        BlankInputValidator,
        Validators.pattern(/^\+?1?\d{9,15}$/),
      ]),
      pushDevice: new FormControl(""),
      emailDevice: new FormControl(""),
      textDevice: new FormControl(""),
      pushResponder: new FormControl(""),
      emailResponder: new FormControl(""),
      textResponder: new FormControl(""),
    });
  }

  goBack() {
    this.loc.back();
  }
  // get user profile details
  getUserProfile() {
    this.userService
      .getUserProfile(this.userDetails.id)
      .then((response: any) => {
        if (response.status === 200) {
          this.usersInfo = new User(response.data);
          const user = new User(response.data as IUser);
          this.ls.setItem(LOCAL_STORAGE.APP_USER, user);
          if (
            this.mappingData &&
            this.mappingData.notification_settings &&
            this.usersInfo.notification_settings
          ) {
            let datas = new MapData(this.mappingData);
            const mappedTypes = datas.getMapTypes();
            const mappedMethods = datas.getMapMethods();
            mappedTypes.forEach((typeChoices) => {
              if (typeChoices.value == DESCRIPTIONS.device_status) {
                this.notificationSettingsDevice =
                  this.usersInfo.notification_settings[typeChoices.type];
                this.typeDevice = typeChoices.type;
              } else {
                this.notificationSettingsResponder =
                  this.usersInfo.notification_settings[typeChoices.type];
                this.typeResponder = typeChoices.type;
              }
            });
            mappedMethods.forEach((methodChoices) => {
              if (methodChoices.value == DESCRIPTIONS.push_notify) {
                this.pushNotificationDevice =
                  this.notificationSettingsDevice[methodChoices.type];
                this.pushNotificationResponder =
                  this.notificationSettingsResponder[methodChoices.type];
              } else if (methodChoices.value == DESCRIPTIONS.email_notify) {
                this.emailNotificationDevice =
                  this.notificationSettingsDevice[methodChoices.type];
                this.emailNotificationResponder =
                  this.notificationSettingsResponder[methodChoices.type];
              } else {
                this.textNotificationDevice =
                  this.notificationSettingsDevice[methodChoices.type];
                this.textNotificationResponder =
                  this.notificationSettingsResponder[methodChoices.type];
              }
            });
            this.userData = mappedMethods;
          }

          this.userProfileForm.patchValue({
            firstname: this.usersInfo.first_name
              ? this.usersInfo.first_name
              : "",
            lastname: this.usersInfo.last_name ? this.usersInfo.last_name : "",
            email: this.usersInfo.email ? this.usersInfo.email : "",
            phonenumber: this.usersInfo.phone ? this.usersInfo.phone : "",
            pushDevice: this.pushNotificationDevice,
            emailDevice: this.emailNotificationDevice,
            textDevice: this.emailNotificationResponder,
            pushResponder: this.pushNotificationResponder,
            emailResponder: this.emailNotificationResponder,
            textResponder: this.textNotificationDevice,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            this.openSnakbar(ERROR_MESSAGES.get_user_details);
          }
        }
      });
  }
  //to open snackbar
  openSnakbar(message) {
    this._snackBar.open(message, "", {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000,
    });
  }
  editUser() {
    const device = {};
    const responder = {};
    this.userData.map((typeChoices) => {
      if (typeChoices.value == this.notificationData.push) {
        typeChoices.Devicevalues = this.pushNotificationDevice;
        typeChoices.Respondvalues = this.pushNotificationResponder;
      }
      if (typeChoices.value == this.notificationData.email) {
        typeChoices.Devicevalues = this.emailNotificationDevice;
        typeChoices.Respondvalues = this.emailNotificationResponder;
      }
      if (typeChoices.value == this.notificationData.text) {
        typeChoices.Devicevalues = this.textNotificationDevice;
        typeChoices.Respondvalues = this.textNotificationResponder;
      }
    });
    for (let i = 0; i < this.userData.length; i++) {
      const { type, Devicevalues } = this.userData[i];
      device[type] = Devicevalues;
    }
    for (let i = 0; i < this.userData.length; i++) {
      const { type, Respondvalues } = this.userData[i];
      responder[type] = Respondvalues;
    }
    this.userNotifications[this.typeResponder] = responder;
    this.userNotifications[this.typeDevice] = device;
    let values = this.userProfileForm.value;
    let submittedValues = {
      first_name: values.firstname,
      last_name: values.lastname,
      phone: values.phonenumber,
      notification_settings: this.userNotifications,
    };
    this.progressBar.mode = "indeterminate";
    this.userService
      .updateProfile(this.userDetails.id, submittedValues)
      .then((response: any) => {
        this.progressBar.mode = "determinate";
        if (response.status === 200) {
          this.openSnakbar(SUCCESS_MESSAGES.user_profile);
          this.userService.profileNameUpdate.next(
            values.firstname + " " + values.lastname
          );
          this.getUserProfile();
          this.userProfileForm.markAsPristine()


        }
      })
      .catch((error) => {
        this.progressBar.mode = "determinate";
        if (error.response) {
          if (error.response.status === 400) {
            this.openSnakbar(ERROR_MESSAGES.edit_profile);
          }
        }
      });
  }
  //to validate input field
  keyValidation(event) {
    var key;
    key = event.charCode;
    return (
      (key > 64 && key < 91) ||
      (key > 96 && key  < 123) ||
       key == 8 ||
       key  == 32 ||
      (key >= 48 && key <= 57)
    );
  }
 toggle(event){
  this.userProfileForm.markAsDirty()
  }
  changePwd()
  {
    const datas = { data : this.userDetails.id }
    this.ds.openDialogueBox(ChangePasswordComponent, datas)
  }
  goToUserDelete() {
    this.dlgOpened = true;
    const datas = { title: "Delete User", message:'Do you want to delete the user profile?',id:this.userDetails.id,orgId:this.orgDetails.id}
    const deleteDlg = this.ds.openDialogueBox(UserDeletePopupComponent, datas)
    deleteDlg.afterClosed().subscribe(()=>{
      this.dlgOpened = false;
    })
  }

  ValidateInput(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9 and +
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
