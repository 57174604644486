import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-av-card-filter',
  templateUrl: './av-card-filter.component.html',
  styleUrls: ['./av-card-filter.component.scss']
})
export class AvCardFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
