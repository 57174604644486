const ERROR_MESSAGES = {
  login_error:
    'Sorry, we couldn’t find an account with that username and/or password. Need help',
  create_account: 'Create account failed',
  get_organizations: 'Error',
  edit_account: 'Edit account failed',
  reset_error: 'Failed to change password',
  email_sent: 'Error on sending email',
  user_add: 'User registration failed',
  account_activation: 'Account activation failed',
  create_label: 'Failed to create label',
  edit_label: 'Failed to edit label',
  get_label: 'Error',
  get_users: 'Error',
  get_user_details: 'Error',
  get_location: 'Error',
  get_aed: 'Error',
  delete_label: 'Failed to delete label',
  server_error: 'Server error occurred',
  label_error: 'Label with this name already exist for the selected type.',
  user_details_add: 'Failed to add user',
  user_edit: 'Failed to edit user',
  network_error:
    'A network error occurred. This could be a CORS issue or a dropped internet connection',
  edit_profile: 'Error',
  image_upload_error: 'The selected file is too big',
  image_type_error: 'Only permitted files are .jpg and .png types.',
  user_profile_photo:
    'Sorry. The selected file size is too large. Up to 1MB size is allowed.',
  user_delete: 'Failed to delete user',
  activate_error: 'Error',
};
const SUCCESS_MESSAGES = {
  user_add: 'User registered successfully',
  account_activation: 'Account activated successfully',
  email_sent: 'A password reset link has been sent to your email',
  reset_success: 'Password has been changed successfully',
  create_account: 'Account created successfully',
  edit_account: 'Account updated successfully',
  create_label: 'Label created successfully',
  edit_label: 'Label edited successfully',
  delete_label: 'Label deleted successfully',
  user_details_add: 'User added successfully',
  user_edit: 'User updated successfully',
  delete_user: 'User deleted successfully',
  user_profile: 'User profile updated successfully',
};

const DESCRIPTIONS = {
  label_title:
    'Labels help you keep your account nice and organized. When you create labels, your managers can tag AEDs, Locations     and Users  with the labels you create.',
  location_downloads:
    'Avive helps you keep your origanization and AEDs in compliance with State and Federal regulations. The following documents are for your records for this location.',
  forgot_password: 'A password reset link will be sent to your email address.',
  device_status: 'Device Status',
  push_notify: 'Push',
  email_notify: 'Email',
  incident_report_viewer: 'External Incident Report Viewer',
  view_additional_incidents:
    "View additional incidents that aren't linked to your AEDs",
  activate_message: 'Get User Info',
  no_records_found: 'No Records Found',
  no_cpr_record: 'This user has no CPR record.',
  schedule_not_available: 'Not Available',
  delete_label: 'Do you want to delete the Label?'
}

export {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  DESCRIPTIONS
};
